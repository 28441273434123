import { FieldProps } from 'pages/Register/_components/Form'
import { STEP_NAMES } from 'pages/Register/_components/Form/Form.types'
import { ROUTE_PATHS, ROUTES } from 'routes/paths'
import i18n from 'config/i18n'
import { LANGUAGES_ENUM } from 'shared/utils/constants'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepCopy = (element: any) => JSON.parse(JSON.stringify(element))

export const buildUrlWithLang = (
  route: ROUTE_PATHS,
  lang: string,
  stepName?: STEP_NAMES,
): string => {
  let newRoute = route as string
  if (route === ROUTES.SIGNUP_STEP) {
    const stepNameToGo: STEP_NAMES = stepName || 'start'
    newRoute = route.replace(':step', stepNameToGo)
  }
  return newRoute.replace(':lang', lang)
}

export const isStepFormValid = (fieldsObj: { [key: string]: FieldProps }) => {
  return Object.values(fieldsObj).every((field) => {
    if (field.error?.active) {
      return false
    }
    if (field.mandatory === false) {
      return true
    }

    if (typeof field.value === 'object' && 'id' in field.value) {
      return field.value.id
    }
    return field.value
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setEmptyObject = (object: any) => {
  const copyObject = deepCopy(object)
  Object.keys(object).forEach((field) => {
    copyObject[field] = ''
  })
  return copyObject
}

export const createEnumFromArray = (data: string[] = []) => {
  const enumObject = data.reduce((acc: { [key: string]: string }, value) => {
    acc[value.toUpperCase()] = value
    return acc
  }, {})
  return enumObject
}

export const getImageSrc = (section: string, imageName: string) => {
  let imageUrl
  try {
    imageUrl = require(
      `pages/${section}/assets/images/${imageName}-${i18n.language}.png`,
    )
  } catch (error) {
    imageUrl = require(
      `pages/${section}/assets/images/${imageName}-${LANGUAGES_ENUM.EN}.png`,
    )
  }

  return imageUrl
}
